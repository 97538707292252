@import './_mixins';

@font-face {
  font-family: myriad-pro-light;
  src: url(../public/Fonts/MyriadPro-Light.otf);
}

body {
   /* width */
  &::-webkit-scrollbar {
  width: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    background: rgb(133, 133, 133);
}
/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}

.counter {
  color: white;
  background: red;
  position: absolute;
  top: 0;
  width: auto;
  height: 15px;
  border-radius: 20px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.8em;
  display: inline-flex;
  align-items: 'center';
}

.productTitle {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  color: #555;
  font-family: myriad-pro-light;

  @include for-phone {
    margin-top: 90px;
  }
  
}

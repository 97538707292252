@import '../../_mixins';
  
.navbar {
    background-color: rgba(180, 180, 180, 0.5);
    box-shadow: inset 0px -2px 0px rgba(138, 137, 137, 0.2);
    padding: 0 !important;

    @include for-phone {
        height: 80px;
        position: fixed !important;
        width: 100% !important; 
        z-index: 2 !important;
        top: 0px;
    }

    .burger-menu {
        width: 2rem;
        height: 2rem;
        top: 20px;
        margin-left: 20px;
        display: none;
        justify-content: space-around;
        flex-flow: column nowrap;

        @include for-phone {
            display: flex;
        }

        div {
            width: 2rem;
            height: 0.25rem;
            background-color: black;
            border-radius: 10px;
        }
    }

    .brand {
        position: absolute;

        @include for-phone {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        img {
            margin-left: 90%;
            width: 120px;
    
            @include for-phone {
                margin-left: 0px;
            }
        }
    }

    .me-auto {
        margin: 0 auto;
        @include for-phone {
            transition: all 0.6s ease-in-out;
            position: fixed !important;
            background-color: rgb(255, 255, 251);
            height: auto;
            width: 200px;
            top: 0;
            left: -500px;
            display: block !important;
            z-index: 1;
            box-shadow: 4px 4px 4px rgb(204, 204, 204);
            padding-bottom: 15px;
        }

        .menu-title-for-phone {
            display: none;
            text-align: center;
            height: 20px;

            @include for-phone {
                display: block;
            }

            .btn-close {
                float: right;
                margin-top: 5px;
                margin-right: 5px;

                &:active, &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }
            }
        }

        .link {
            padding: 15px 10px 17px 10px;
            margin: 0 5px 0 5px;
            text-decoration: none;
            color: white;
            font-family: myriad-pro-light;
            border-bottom: transparent 2px solid;
            transition: all 0.3s;

            @include for-phone {
                font-size: 1.5em;
                padding: 5px !important;
                display: block !important;
                color: rgb(137,123,45);
            }
    
            &:hover {
                border-bottom: rgb(65, 65, 65) 2px solid;
            }
        }

        .selected {
            border-bottom: rgb(65, 65, 65) 2px solid ;
            color: rgb(65, 65, 65)
        }
    }

    .bag {
        position: absolute; 
        right: 35px;
        cursor: pointer;

        @include for-phone {
            top: 20px;
        }

        & > img {
            @include for-phone {
                width: 2rem;
            }
        }

        span {
            color: white; 
            background: red; 
            position: absolute; 
            top: 2px;
            min-width: 15px;
            height: 15px;
            border-radius: 20px;
            padding: 4px 5px 5px 5px;
            font-size: 0.8em;
            display: inline-flex;
            align-items: center;
        }
    }
}

.navbar-home {
    position: absolute !important; 
    width: 100% !important; 
    z-index: 2 !important;

    @include for-phone {
        position: fixed !important;
    }
}

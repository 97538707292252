@import '../../_mixins';

input:hover, 
input:active, 
input:focus
{
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

form {
    width: 50%;
    margin: auto;
    margin-top: 30px;

    @include for-phone {
        width: 90%;
        margin-top: 100px;
    }

    .row {
        @include for-phone {
            display: block;
        }
    }

    .info-user-input {
        border: none; 
        border-bottom: 1px solid rgb(187, 187, 187); 
        border-radius: 0;
    }

    h4 {
        margin-top: 30px
    }

    .delivery {
        display: flex; 
        flex-direction: row; 
        justify-content: space-between;
        margin-top: 30px;

        @include for-phone {
            display: block;
            margin: auto;
        }

        .delivery-place {
            width: 300px;

            p {
                @include for-phone {
                    text-align: center;
                }
            }

            @include for-phone {
                margin-top: 30px !important;
                width: 90%;
                margin: auto;
            }
        }
    }

    textarea {
        display: none;
    }

    .send-btn {
        color: white;
        border: 2px solid rgb(137,123,45);
        background: rgb(137,123,45);
        padding-bottom: 5px;
        width: 150px;
        transition: all 0.2s ease-in-out;

        @include for-phone {
            width: 90%;
            display: block;
            margin: auto;
        }
    
        &:hover {
            border: 2px solid white ;
            box-shadow: 0px 0px 5px 4px rgb(126, 126, 126) !important;
        }
    }
}

.send-result {
    text-align: center;
    height: 120px; 
    margin-bottom: 20px;

    @include for-phone {
        margin-top: 100px;
    }
    
    .spinner {
        width: 80px; 
        height: 80px;
    }

    .successful{
        color: green
    }

    .error {
        color: red
    }
}

.empty-cart-msg {
    text-align: center;
    padding: 30px 0 30px 0;
        
    @include for-phone {
        margin-top: 80px;
    }
    
}
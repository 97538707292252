@import '../../_mixins';
    .info-about-us {
        width: 50%; 
        margin: auto;
        margin-top: 20px;
        padding: 0 10px 0 10px;
        text-align: center;
        background-image: url('../../../public/images/background/1_20.png');
        background-repeat: none;
        background-size: cover;
    
        @include for-phone {
            margin-top: 90px;
            width: 90%;
        }
    
        h2 {
            text-align: center;
            font-family: myriad-pro-light;
            font-weight: bold;
        }
    
        p {
            font-family: myriad-pro-light;
        }
    
        img {
            @include for-phone {
                display: block !important;
                width: 100%;
                flex: none;
            }
        }
    
        .row {
            margin-bottom: 80px;
    
            @include for-phone {
                margin-bottom: 0;
            }
    
            .text {
                @include for-phone {
                    display: block !important;
                    width: 100%;
                    flex: none;
                }
        
                p {
                    line-height: 200%;
        
                    @include for-phone {
                        line-height: 150%;
                    }
                }

                p:nth-child(2) {
                    padding-top: 20px;
                }
            }
        }
    
        video {
            @include for-phone {
                height: 200px;
                width: 95%;
            }
        }
    }

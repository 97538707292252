@import '../../_mixins';

.div-panel {
    background: rgb(80, 80, 80);
    width: 400px;
    max-height: 500px;
    min-height: 100px;
    position: fixed;
    z-index: 2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: right 0.8s ease-in-out, top 0.3s ease-in-out;

    @include for-phone {
        width: 350px;
        top: 0 !important;
    }

    .close-btn {
        top: 20px;
        position: absolute; 
        left: 20px; 
        background-color: none !important; 
        border: none !important;

        &:active, &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    h4 {
        text-align: center; 
        color: white; 
        margin-top: 15px;
        font-family: myriad-pro-light;
        font-weight: bold;
    }

    span {
        margin-top: 5px;
    }

    .panel {
        width: 90%;
        margin: auto;
        min-height: 100px;
        max-height: 260px;
        margin-top: 25px;
        background: rgb(80, 80, 80);
        overflow-y: scroll;
        color: white;
    
        .title {
            width: 130px;
            text-overflow: ellipsis; 
            overflow: hidden;
            white-space: nowrap;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            
        }
      
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
      
        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px grey;
            background: rgb(46, 46, 46);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(8, 8, 8);
        }
    
        & .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: white(80, 80, 80);
            color: black;
            transition: all 0.5s;
    
            &:hover {
                background-color: rgb(90, 90, 90);
                color: white
            }
        }
    
        .delete {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .checking-cart {
        width: 90%; 
        margin: auto;
        background: rgb(80, 80, 80); 
        color: white;
        padding: 10px 0 10px 0;

        span {
            float: right; 
            margin-top: 0 !important;
        }
       
        &-btn {
            margin-top: 10px;
            width: 100%;
            @include button
        }
    }
}

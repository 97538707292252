@mixin button {
    color: white !important;
    border: 2px solid rgb(137,123,45) !important;
    background: rgb(137,123,45) !important;

    &:hover {
        border: 2px solid white !important;
        box-shadow: 0px 0px 1px 4px rgb(126, 126, 126) !important;
    }
}

@mixin for-phone {
    @media (max-width: 800px) { @content ; }
}

@mixin for-tablet {
    @media (max-width: 1280px) and (min-width: 716px) { @content ; }
}
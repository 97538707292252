
.snow {
    width: 100%;
    z-index: 3 !important;
    position: fixed;
    top:0px;
  
    .snowflake {
      animation: snow 5s 1s infinite linear;
      width: 30px;
      position: absolute;
      left: 10%;
      opacity: 0;
      opacity: 1;
    }
    .snowflake1 {
      animation: snow 5s 0.5s infinite linear;
      width: 15px; 
      position: absolute; 
      left: 60%;
      opacity: 0;
    }
    .snowflake2 {
      animation: snow 5s 1.5s infinite linear;
      width: 20px; 
      position: absolute; 
      left: 90%;
      opacity: 0;
    }
    .snowflake3 {
      animation:snow 5s 3s infinite linear;
      width: 20px; 
      position: absolute; 
      left: 20%;
      opacity: 0;
    }
    .snowflake4 {      
      animation: snow 5s 4.5s infinite linear;
      width: 40px; 
      position: absolute; 
      left: 50%;
      opacity: 0;
    }
    .snowflake5 {
      animation: snow 5s 2.5s infinite linear;
      width: 30px; 
      position: absolute; 
      left: 80%;
      opacity: 0;
    }
    .snowflake6 {
      animation: snow 5s 1.8s infinite linear;
      width: 20px; 
      position: absolute; 
      left: 30%;
      opacity: 0;
    }
    .snowflake7 {
      animation:snow 5s 3.5s infinite linear;
      width: 30px; 
      position: absolute; 
      left: 40%;
      opacity: 0;
    }
    .snowflake8 {      
      animation: snow 5s 6s infinite linear;
      width: 40px; 
      position: absolute; 
      left: 55%;
      opacity: 0;
    }
    .snowflake9 {
      animation: snow 5s 2s infinite linear;
      width: 20px; 
      position: absolute; 
      left: 70%;
      opacity: 0;
    }
  
  } 
  
  @keyframes snow{
    0%{transform:translateY(0px) rotateX(0deg); opacity: 0;}
    10%{transform:translateY(100px) rotateX(180deg); opacity: 1;}
    20%{transform:translateY(200px) rotateX(0deg) rotateZ(180deg); opacity: 1;}
    30%{transform:translateY(300px) rotateX(180deg); opacity: 1;}
    40%{transform:translateY(400px) rotateX(0deg);opacity: 1; }
    50%{transform:translateY(500px) rotateX(180deg);opacity: 1; }
    60%{transform:translateY(600px) rotateX(0deg) rotateZ(180deg); opacity: 1; }
    70%{transform:translateY(700px) rotateX(180deg); opacity: 1;}
    80%{transform:translateY(800px) rotateX(0deg);opacity: 1; }
    90%{transform:translateY(1000px) rotateX(180deg);opacity: 1; }
    100%{transform:translateY(1200px) rotateX(0deg);opacity: 1; }
  }
  
@import '../../_mixins';

footer {
    background: black; 
    height: 500px;

    .contacts-info {
        margin: auto;
        margin-bottom: 20px;
        margin-top: 60px;
        width: 500px;
        
        @include for-phone {
            margin: auto;
            display: block !important;
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;

        }

        .contact {
            width: 300px; 
            margin: auto;
        
            p {
                color: white;
                margin-left: 13px
            }
        }

        .social-media {
            text-align: center;
            width: 100%;
            margin-bottom: 10px;

            &--contact {
                margin-right: 10px;
                width: 35px;
    
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .copyright {
        margin-top: 60px;

        @include for-phone {
            margin-top: 50px;
        }
        
    }

    .partners {
        margin: auto;
        width: 500px;
        margin-top: 60px;

        @include for-phone {
            margin-top: 50px;
            width: 70%;
        }
        
        img {
            width: 100px;

            @include for-phone {
                width: 80px;
            }
        }
    }
}
